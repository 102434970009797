import "core-js/stable";
import "regenerator-runtime/runtime";
import ResponsiveAutoHeight from "responsive-auto-height";
import { initScrollReveal } from "./scroll-reveal";
import Accordion from 'accordion-js';

/*==========================================
 Variables
===========================================*/

const htmlElement = document.querySelector("html");
const bodyElement = document.body;
const headerElement = document.querySelector("header#o-header");
const siteOverlayElement = document.querySelector("#a-site-overlay");
const preLoaderOverlayElement = document.querySelector("#a-pre-load-overlay");

/*==========================================
 Click Events Listeners
===========================================*/

bodyElement.addEventListener("click", evt => {});

/*==========================================
 Init plugins
===========================================*/

/*==========================================
Pre Loader
===========================================*/

const ready = fn => {
    if (document.readyState != "loading") {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
};

const hidePreloader = () => {
    preLoaderOverlayElement.classList.add("v-hidden");
    htmlElement.style.fontSize = null;

    setTimeout(() => {
        initScrollReveal();

        setTimeout(() => {
            preLoaderOverlayElement.classList.add("display-none");
        }, 200);
    }, 400);
};

setTimeout(() => {
    ready(hidePreloader);
}, 300);

/*==========================================
Equal Height
===========================================*/

const runMatchHeight = () => {
    const elements = [].slice.call(document.querySelectorAll(".match-height"));
    if (elements && elements.length) {
        const m = new ResponsiveAutoHeight(".match-height");
    }
};

runMatchHeight();

/*==========================================
Hamburger
===========================================*/

const initHamburgerMenu = () => {
    const headerNav = document.getElementById("js-header-nav");
    const hamburger = document.getElementById("js-hamburger");

    hamburger.onclick = () => {
        hamburger.classList.toggle('active');
        headerNav.classList.toggle('active');
    };
};

initHamburgerMenu();

/*==========================================
Accordion
===========================================*/
const accordions = [].slice.call(document.querySelectorAll(".accordion-container"));
if (accordions && accordions.length) {
    new Accordion('.accordion-container').open(0);
}
